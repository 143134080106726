@import "/src/styles/variables";

.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}

.titleWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  @media screen and (width < 1700px) {
    height: 80%;
  }

  @media screen and (width > 1700px) {
    transform: translateX(-3%);
  }

  @media screen and (width < $tablet) {
    flex-direction: column;
    margin-left: 0;
    height: 80%;
    justify-content: flex-end;
  }
}

.titleBox {
  position: relative;
  max-width: 510px;
  width: max-content;
  font-family: $PTSans-font;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media screen and (width > 1700px) {
    transform: translateX(-10%);
  }

  @media screen and (width < $desktop) {
    width: 70%;
  }

  @media screen and (width < $tablet) {
    margin-right: 0;
    padding: 2rem;
    width: 100%;
  }
}

.titleChapter {
  font-family: inherit;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.4rem;
  color: $accent-color;

  @media screen and (width < $tablet) {
    font-size: 1rem;
    line-height: 1.1rem;
  }
}

.titleHeading {
  font-family: inherit;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 5rem;
  line-height: 4.5rem;
  letter-spacing: 0.06em;
  margin-bottom: 1rem;

  @media screen and (width < $tablet) {
    font-size: 3rem;
    line-height: 3rem;
  }
}

.titleTextContent {
  font-family: $rosario-font;
  font-weight: 400;
  font-size: 1.15rem;
  line-height: 1.75rem;
  width: 80%;

  @media screen and (width < $tablet) {
    font-size: 1rem;
    line-height: 1.8rem;
    width: 100%;
  }
}

.slideDesc {
  width: 100%;
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
}

.slideDescContent {
  font-family: $rosario-font;
  font-weight: 400;
  font-style: italic;
  font-size: 0.875rem;
  line-height: 1.1rem;
  width: 80%;
}

.titleImgBox {
  position: relative;
  width: 100%;
  max-width: 1050px;
  height: 100%;

  span {
    &:has(img):has(.titleImgMobile) {
      display: none !important;
    }
    &:has(img):has(.titleImg) {
      display: block !important;
    }
  }

  @media screen and (width > 1700px) {
    transform: translateX(-10%);
  }

  @media screen and (width < $desktop) {
    max-width: unset;
    transform: translate(0, 0);
  }

  @media screen and (width < $tablet) {
    width: 100%;
    height: 100%;
    max-width: 100%;
    gap: 1rem;

    span:has(img) {
      width: 100%;
    }

    span {
      &:has(img):has(.titleImgMobile) {
        display: block !important;
      }
      &:has(img):has(.titleImg) {
        display: none !important;
      }
    }
  }
}

.titleImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  transform: translate(0, 0);

  @media screen and (width < $desktop) {
    object-position: center;
    object-fit: contain;
  }

  @media screen and (width < $tablet) {
    display: none !important;
  }
}

.titleImgMobile {
  display: none;

  @media screen and (width < $tablet) {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
  }
}

.titleImgBanner {
  position: absolute;
  bottom: 5%;
  left: 50%;
  max-width: 600px;
  width: 100%;
  transform: translate(-50%, 0%);

  @media screen and (width < $desktop) {
    max-width: 400px;
  }

  @media screen and (width < $tablet) {
    position: static;
    max-width: 90%;
    width: 100%;
    transform: translate(0, 0);
    margin: 8px auto 0;
  }
}