@import "/src/styles/variables";

.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $desktop) {
    background-position: 65%;
  }

  @media screen and (width < $tablet) {
    overflow: auto;
    height: unset;
    background-image: none !important;
    background: $dark-color;
  }
}

.mobileImg {
  display: none;

  @media screen and (width < $tablet) {
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    min-height: 70vh;
    width: 100%;
  }
}

.slideWrapper {
  height: 85%;
  width: 100%;
  margin-left: 8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (width < $desktop) {
    margin-left: 4rem;
  }

  @media screen and (width < $tablet) {
    margin-top: 0;
    height: 100%;
    margin-left: 0;
    flex-direction: column-reverse;
    justify-content: flex-end;
  }
}

.changeLayout {
  justify-content: space-evenly;
}

.slideChartBox {
  max-height: 600px;
  height: 100%;

  @media (width < $tablet) {
    max-height: none;
  }
}

.slideBoxHeader {
  margin-left: 3.5rem;
  margin-bottom: 3rem;
  font-family: $PTSans-font;
  text-align: left;
  text-transform: uppercase;
  max-width: 600px;

  @media screen and (width < $tablet) {
    margin-left: 0;
    margin-bottom: 0;
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    text-align: left;
  }
}

.slideBoxHeader p {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.07em;
}

.slideBoxHeader span {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.1rem;
  letter-spacing: 0.05em;
  opacity: 0.6;
}

.slideChart {
  width: 622px;
  max-height: 450px;
  height: 90%;
  font-weight: 700;

  @media (width < $tablet) {
    width: 780px;
    height: 680px;
    max-height: none;
    max-width: calc(100vw - 70px);
    margin: 30px auto;
    svg {
      overflow: visible !important;
    }
  }

  @media screen and (width <$mobile) {
    transform: translateX(-10%);
  }
}

.slideBanner {
  max-width: 495px;
  align-self: flex-end;
  padding-bottom: 1rem;
  margin: 0 auto;

  @media screen and (width < $tablet) {
    max-width: 495px;
    width: 95%;
    position: static;
    margin-top: -50px;
    margin-bottom: 50px;
  }
}

.slideBox {
  color: $vanilla-color;
  max-width: 470px;

  h2 {
    font-family: $PTSans-font;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 35px;
    letter-spacing: 0.06em;
    margin-bottom: 20px;
  }

  p {
    font-family: $rosario-font;
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 29px;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media screen and (width < $tablet) {
    padding: 3rem 1rem;

    h2 {
      font-size: 1.6rem;
      line-height: 35px;
    }
  
    p {
      font-size: 0.9rem;
      line-height: 19px;
    }
  
  }
}