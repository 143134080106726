@import "/src/styles/variables";

.container {
  background-color: $dark-blue;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.content {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  font-family: $PTSans-font;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  background-color: $accent-color;
  border: none;
  padding: 15px 35px;
  cursor: pointer;
  margin-top: 80px;
}

.video {
  width: 100%;
  max-width: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (width < $tablet) {
    max-width: none;
  }
}

.videoWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.logo {
  opacity: 0;
  animation: fadeLogo 2.5s ease-in 0.5s 1 normal forwards;
}

@keyframes fadeLogo {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}