@import "src/styles/variables.scss";

.wrapper {
  width: 100%;
  height: 100%;
}

.verticalWrapper {
  width: 100%;
  height: 100%;

  @media (width < $mobile) {
    padding-top: 45px;
  }
}

.verticalWrapperLabelIcons {
  @media (width < $mobile) {
    width: 90%;
    transform: translateX(5%);
  }
}

.wrapper svg,
.verticalWrapper svg {
  overflow: visible;
}

.xLabel tspan {
  font-family: $PTSans-font;
  font-size: 0.9rem;
  font-weight: 700;
  fill: $vanilla-color;
  text-align: center;
  letter-spacing: 0.05em;

  @media (width < $mobile) {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 15px;
  }
}

.labelHidden {
  display: none;
}

.yLabel tspan {
  font-family: $PTSans-font;
  font-size: 0.825rem;
  font-weight: 700;
  fill: #626163;
  line-height: 18px;
  letter-spacing: 0.05em;

  @media (width < $mobile) {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 15px;
  }
}

.barLabel {
  font-family: $PTSans-font;
  font-weight: 600;
  fill: $vanilla-color;
  letter-spacing: 0.7px;
  font-size: 1rem;

  @media (width < $mobile) {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 15px;
  }
}

.barCustomLabel {
  font-family: $PTSans-font;
  font-weight: 400;
  fill: $latte-color;
  letter-spacing: 0.7px;
  font-size: 1rem;

  @media (width < $mobile) {
    font-size: 0.75rem;
    font-weight: 700;
    line-height: 15px;
  }
}

.labelIcon {
  max-width: 75px;

  @media (width < $mobile) {
    max-width: 35px;
  }
}