@import "src/styles/variables.scss";

.point {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
  cursor: pointer;
}

.pointNumber {
  font-family: $PTSans-font;
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.3);
  transform: rotate(-90deg);
  margin-right: 17px;
}

.pointNumberActive {
  color: $accent-color;
}

.pointCircle {
  width: 18px;
  height: 18px;
  background-color: $black-color;
  border-radius: 50%;
  border: 1px solid rgba(207, 207, 207, 0.1);
}

.pointCircle:hover {
  background-color: $accent-color;
}

.pointCircleActive {
  background-color: $accent-color;
  border: 1px solid transparent;
}