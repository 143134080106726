// colors
$accent-color: #ff4337;
$dark-color: #1e1f23;
$black-color: #191919;
$dark-blue: #1E1F23;
$light-color: #d9d9d9;
$silver-color: #cfcfcf;
$vanilla-color: #fcf3e7;
$latte-color: #f5ebdd;
$khaki-color: #FFDEB1;
$lightgray-color: #353c43;
$middlegray-color: #5a595a;
$darkgray-color: #2d2d2f;
$darkred-color: #583230;
$purple-color: #5f78ff;

$darkblue-color: #26282F;
$mediumblue-color: #36383D;
$grayDot-color: #5C5C62;

$gray-border: #CFCFCF1A;

// screen
$mobile: 768px;
$tablet: 992px;
$desktop: 1281px;

// fonts
$PTSans-font: "PT Sans", sans-serif;
$rosario-font: "Rosario", sans-serif;
