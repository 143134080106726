@import "src/styles/variables.scss";

.image {
  margin-bottom: 70px;
  animation: 10s linear 0s infinite rotateTape forwards;
  animation-play-state: paused;
}

.icon {
  cursor: pointer;
  margin-bottom: 35px;
}

.progress {
  display: flex;
  align-items: center;

  @media (width < $desktop) {
    width: 100%;
  }
}

.progressBar {
  width: 442px;
  height: 4px;
  margin-right: 24px;
}

.time {
  font-family: $PTSans-font;
  font-size: 12px;
  font-weight: 700;
  color: #ffff;
  margin: 0 15px;
}


@keyframes rotateTape {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.animate {
  animation-play-state: running;
}

.video {
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videoWrapper {
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}