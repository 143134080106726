@import "/src/styles/variables";

.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}

.slideWrapper {
  font-family: $PTSans-font;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4%;
  width: 100vw;
  margin-right: 7rem;

  @media screen and (width < $desktop) {
    height: 100%;
  }

  @media screen and (width < $tablet) {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
    margin-bottom: 0rem;
    width: 100%;
    gap: 70px;
  }
}

.slideImageBox {
  position: relative;
  max-width: 50%;
  min-width: 600px;
  width: 750px;
  height: 530px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  @media screen and (width < 1650px) {
    min-width: unset;
    width: 50%;
    overflow: visible;
  }

  span {
    &:has(img):has(.slideImageMobile) {
      display: none !important;
    }
    &:has(img):has(.slideImage) {
      display: block !important;
    }
  }

  @media screen and (width < $tablet) {
    min-width: unset;
    height: unset;
    max-width: 100vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      &:has(img):has(.slideImageMobile) {
        display: block !important;
      }
      &:has(img):has(.slideImage) {
        display: none !important;
      }
    }
  }


}

.slideImage {
  display: block;
  object-fit: contain;
  object-position: right;

  @media screen and (width > 1800px) {
    object-position: center;
  }

  @media screen and (width < $desktop) {
    transform: translate(0, 0);
    object-position: center;
  }

  @media screen and (width < $tablet) {
    display: none;
    object-fit: contain;
    height: unset;
    max-height: 60vh;
  }
}

.slideImageMobile {
  display: none;

  @media screen and (width < $tablet) {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
  }
}

.absoluteBanner {
  display: block;
  max-width: 500px;
  width: 80%;

  @media screen and (width < $tablet) {
    display: inline-block;
    transform: translate(0, 0);
    position: static;
    width: 95%;
    max-width: 95%;
    margin: 0 auto;
  }
}

.absoluteBanner2 {
  display: block;
  max-width: 500px;
  width: 80%;

  @media screen and (width < $tablet) {
    display: inline-block;
    transform: translate(0, 0);
    position: static;
    width: 95%;
    max-width: 95%;
    margin: 0 auto;
  }
}

.slideDataBox {
  height: 80%;
  min-width: 30%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 4%;

  article {
    max-width: unset;
  }

  @media screen and (width > 1800px) {
    justify-content: center;
    width: 33%;
  }

  @media screen and (width < 1650px) {
    justify-content: center;
    width: 45%;
  }

  @media screen and (width < $desktop) {
    flex-direction: column;
  }

  @media screen and (width < $tablet) {
    width: 100%;
    gap: 70px;
  }
}

.slideChart {
  max-width: 480px;
  width: 100%;
  max-height: 500px;
  height: 60%;

  @media screen and (width < 1450px) {
    width: 90%;
    height: 300px;
    transform: translateX(-5%);
  }
}

.slideChart h2 {
  font-size: 1.65rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.06em;
  margin-bottom: 2rem;
  text-transform: uppercase;
  margin-left: 2rem;
  margin-bottom: 2rem;

  @media screen and (width < $mobile) {
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0.06em;
    margin-left: 3rem;
  }

  @media screen and (width < $tablet) {
    width: 100%;
    margin-bottom: 6rem;
    min-height: 350px;
    padding: 0;
    transform: translateX(-1rem);
  }
}

.titleWrapper {
  @media screen and (width < $tablet) {
    justify-content: flex-start;
    margin-left: 0;
  }
}