@import "src/styles/variables.scss";

.container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  background-color: $dark-color;
}

.wrapper {
  width: 85%;
  height: 85%;
  padding: 20px;
  background-color: #26282F;
  border: 1px solid #CFCFCF1A;
  box-shadow: -5px 0px 9px 0px #0000001A;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (width < $tablet) {
    width: 95%;
    height: 75%;
    padding: 10px;
  }
}

.imageWrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  cursor: grab;
}

.zoomTool {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 20px;
}

.zoomTool input[type="range"] {
  appearance: none;
  border-radius: 8px;
  height: 3px;
  width: 180px;
  outline: none;
}

.zoomTool input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: #ffdeb1;
  cursor: pointer;
}

.zoomTool input {
  accent-color: $vanilla-color;
}

.button {
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.backButton {
  position: absolute;
  top: 20px;
  left: 25px;
  display: flex;
  align-items: center;
}

.backButton p {
  font-family: $PTSans-font;
  font-size: 14px;
  font-weight: 700;
  color: $vanilla-color;
  text-transform: uppercase;
  margin-left: 10px;
}
