@import "/src/styles/variables";

.footerBox {
  position: absolute;
  z-index: 2;
  bottom: 1rem;
  right: 1rem;
  color: $light-color;
  text-transform: uppercase;
  font-family: $PTSans-font;
  font-size: 0.9rem;
  line-height: 1.2rem;
  letter-spacing: 0.02em;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  @media (max-width: $mobile) {
    flex-direction: row;
    align-items: center;
    right: 10px;
  }
}

.footerBoxBtn {
  border: none;
  background: no-repeat url("../../../../public/assets/pencil-gray.svg") center;
  width: 50px;
  height: 50px;
  padding: 10px;
  border-radius: 50%;
  background-color: $dark-blue;
  position: relative;
  box-shadow: 7px 10px 16px 0px #0D0D114D;
  box-sizing: content-box;
}

.btnIconActive {
  background: no-repeat url("../../../../public/assets/pencil.svg") center;
  background-color: $accent-color;
  cursor: pointer;
}

.tasks {
  position: absolute;
  top: -5px;
  right: -5px;
  min-height: 19px;
  min-width: 22px;
  padding: 2px;
  border-radius: 50%;
  background-color: $light-color;
  color: $dark-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
}

.footerBoxLight {
  position: absolute;
  bottom: -500%;
  right: -500%;
  width: 36.6875rem;
  height: 36.6875rem;
  flex-shrink: 0;
  border-radius: 50%;
  background: rgba(251, 227, 226, 0.03);
  filter: blur(65.5px);
}

@media screen and (width < $tablet) {
  .footerBoxTxt {
    display: none;
  }

  .footerBoxLight {
    bottom: -400%;
    right: -400%;
    width: 20rem;
    height: 20rem;
  }
}

.activeSlideOnMobile {
  display: none;
  font-family: $PTSans-font;
  font-size: 16px;
  font-weight: 400;
  color: $light-color;
  width: 57px;
  height: 57px;
  background-color: #2A2C32;
  border: 1px solid #CFCFCF1A;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  gap: 15px;

  @media (width < $tablet) {
    display: flex;
  }
}