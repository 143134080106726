@import "../../styles/variables.scss";

.header,
.main,
.footer {
  background-color: $dark-color;
  color: $light-color;
}

.header {
  color: $light-color;
  position: relative;
  z-index: 11;

  @media (max-width: $mobile) {
    background-color: $dark-color;
  }
}