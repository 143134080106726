@import "/src/styles/variables";

.modalBg {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 30, 33, 0.75);
  backdrop-filter: blur(2px);

  @media (max-width: $mobile) {
    z-index: 12;
  }
}

.modalBorder {
  position: relative;
  max-width: 868px;
  width: 100%;
  height: 492px;
  padding: 11px;
  border: 1px solid #cfcfcf1a;
  border-radius: 2px;

  @media screen and (width < $tablet) {
    max-width: 100%;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    padding: 0;
    border-radius: 0;
  }
}

.dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: $grayDot-color;
  position: absolute;
  display: block;

  @media screen and (width < $tablet) {
    display: none;
  }
}

.dot1 {
  top: 3px;
  left: 3px;
}

.dot2 {
  top: 3px;
  right: 3px;
}

.dot3 {
  bottom: 3px;
  left: 3px;
}

.dot4 {
  bottom: 3px;
  right: 3px;
}

.modalWindow {
  position: relative;
  background-color: $vanilla-color;
  height: 100%;
  border-radius: 2px;
  overflow: hidden;
  display: flex;

  span:has(img) {
    display: inline-flex !important;
  }

  @media screen and (width < $tablet) {
    height: 100%;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
  }
}

.modalWindow_imgBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 40%;
}

.modalWindow_imgBox span {
  width: 100%;
  height: 100%;
}

.modalWindow_imgBox span img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.modalWindow_imgBox p {
  width: 75%;
  text-align: center;
  font-family: $PTSans-font;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.125rem;
  letter-spacing: 0.0375rem;
  text-transform: uppercase;
  margin: 20px;
}

.modalWindow img {
  max-width: none;
}


.modalImg {
  @media screen and (width < $tablet) {
    max-height: 100%;
    background-position: center;
    background-size: cover;
  }
}

.modalTextBox {
  color: $dark-color;
  padding: 60px;

  @media screen and (width < $tablet) {
    max-width: 315px;
    padding: 30px;
  }
}

.modalTextBox h2 {
  font-family: $PTSans-font;
  font-size: 1.7rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.06em;
}

.modalTextBox h3 {
  font-family: $PTSans-font;
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 0.07em;
  margin-bottom: 25px;
}

.modalTextBox p {
  font-family: $rosario-font;
  font-size: 1rem;
  line-height: 1.8rem;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  cursor: pointer;
  background: no-repeat url("../../../public/assets/closeBtn.svg") center/contain;

  &:focus {
    outline: 3px solid $dark-color;
  }

  @media (max-width: $tablet) {
    width: 45px;
    height: 45px;
  }
}