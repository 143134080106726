@import "src/styles/variables.scss";

.container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(23, 24, 26, 0.7);
  width: 100%;
  height: 100%;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;

  @media (width < $tablet) {
    width: 100%;
  }
}

.wrapper {
  position: relative;
  width: 82%;
  height: calc(100% - 55px);
  display: flex;
  justify-content: center;
  border-radius: 2px;
  padding: 11px;
  border: 1px solid #CFCFCF1A;

  @media (width < $tablet) {
    padding: 0;
    width: 100%;
    height: 100%;
    border: none;
  }

  &:has(.content):has(.pictureContainer),
  &:has(.content):has(.videoContainer) {
    width: max-content;
    height: max-content;

    @media (width < $tablet) {
      padding: 0;
      width: 100%;
      height: 100%;
      border: none;
    }
  }
}

.content {
  background-color: $dark-color;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 65%;
  border-radius: 2px;
  border: 1px solid #CFCFCF1A;
  overflow: hidden;

  @media (width < $tablet) {
    display: none;
  }
}

.contentFullWidth {
  width: 100%;

  @media (width < $tablet) {
    display: flex;
  }
}

//gallery

.carousel {
  width: 80%;
  max-width: 900px;
  position: relative;
}

.carouselSlide {
  min-height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (width < $tablet) {
    min-height: unset;
    height: 100dvh;
  }
}

.carouselSlide span {
  width: auto;
  height: auto;
}

.number {
  font-family: $PTSans-font;
  font-size: 14px;
  font-weight: 700;
  color: $vanilla-color;
  text-align: center;
  margin-bottom: 10px;
}

.arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  background: transparent;
  border: none;
}

.arrow:hover * {
  fill: $accent-color;
}

.nextArrow {
  right: 22%;

  @media (width < $tablet) {
    right: -30px;
  }
}

.prevArrow {
  left: 22%;

  @media (width < $tablet) {
    left: -30px;
  }
}

//tasks 

.taskContainer {
  width: 35%;
  height: 100%;
  display: flex;
  position: relative;

  @media (width < $tablet) {
    width: 100%;
    flex-direction: column;
    justify-content: space-between;
  }
}

.task {
  background-color: $vanilla-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  position: relative;
  z-index: 1;

  @media (width < $tablet) {
    justify-content: flex-start;
    height: 100%;
  }
}

.taskContent {
  padding: 85px 50px 40px 71px;

  @media (width < $tablet) {
    padding: 65px 50px 35px 30px;
  }
}

.taskTabsMobile {
  display: none;

  @media (width < $tablet) {
    display: flex;
    align-items: center;
  }
}

.taskTabsMobile p {
  font-family: $PTSans-font;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-left: 8px;
}

.taskTabsMobile img {
  @media (width < $tablet) {
    width: 20px;
  }
}

.taskTabs {
  font-family: $PTSans-font;
  position: absolute;
  top: 56px;
  left: -40px;

  @media (width < $tablet) {
    position: static;
    background-color: $vanilla-color;
    padding: 50px 30px;
    display: flex;
    justify-content: space-between;
  }
}

.taskTabsList {
  display: flex;
  flex-direction: column;
  gap: 23px;

  @media (width < $tablet) {
    flex-direction: row;
    gap: 10px;
  }
}

.taskTab {
  width: 60px;
  height: 51px;
  background-color: $lightgray-color;
  color: $dark-color;
  font-size: 22px;
  font-weight: 900;
  display: flex;
  align-items: center;
  padding: 0 30px 0 15px;
  border-radius: 5px;
  cursor: pointer;

  @media (width < $tablet) {
    width: 57px;
    height: 57px;
    background-color: transparent;
    justify-content: center;
    border-radius: 50%;
    border: 1px solid #dbd3ca;
    padding: 0;
    font-size: 18px;
  }
}

.taskTabActive {
  background-color: $accent-color;
  z-index: 2;

  @media (width < $tablet) {
    border: 1px solid transparent;
  }
}

.taskHeader {
  font-family: $PTSans-font;
  font-size: 26px;
  line-height: 35px;
  font-weight: 800;
  color: $dark-color;
  text-transform: uppercase;
  letter-spacing: 6%;

  @media (width < $tablet) {
    font-size: 22px;
    line-height: 30px;
  }
}

.taskLevel {
  font-family: $PTSans-font;
  font-size: 12px;
  font-weight: 700;
  color: #36383D;
  text-transform: uppercase;
  margin-bottom: 30px;
  opacity: 0.5;
  margin: 5px 0 25px 0;
}

.taskCommand {
  font-family: $rosario-font;
  font-size: 16px;
  font-weight: 600;
  color: $mediumblue-color;
  margin-bottom: 30px;
  line-height: 1.625rem;
  letter-spacing: 0.01rem;

  @media (width < $tablet) {
    font-weight: 400;
    line-height: 26px;
  }
}

.taskItem {
  position: relative;
  font-family: $rosario-font;
  font-size: 16px;
  line-height: 25px;
  padding: 0 0 15px 28px;
  color: $dark-color;

  @media (width < $tablet) {
    padding: 0 0 15px 18px;
  }
}

.taskItem::before {
  content: "";
  position: absolute;
  left: 0;
  top: 9px;
  background-color: $accent-color;
  width: 9px;
  height: 9px;
  border-radius: 50%;
}

.minorList {
  font-size: 0.9rem;
  padding-left: 20px;
  list-style: circle;
}

.taskRules {
  background-color: $latte-color;
  padding: 39px 65px 43px 71px;

  @media (width < $tablet) {
    padding: 24px 30px;
  }
}

.taskRule {
  font-family: $PTSans-font;
  font-size: 14px;
  font-weight: 600;
  color: $dark-color;
  text-transform: uppercase;
  margin-bottom: 15px;
  display: flex;
  align-items: center;

  @media (width < $tablet) {
    font-size: 10px;
    font-weight: 700;
    line-height: 15px;
    margin-bottom: 10px;
  }
}

.taskRule span {
  width: auto;
  height: auto;
}

.taskRule img {
  margin-right: 19px;
}

// audio

.audioContainer {
  display: flex;
  justify-content: space-between;
  padding: 0 75px;
  width: 100%;


  @media (width < $desktop) {
    padding: 0 50px;
  }

  @media (width < $tablet) {
    padding: 0;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  @media (width < $mobile) {
    height: 100dvh;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

.audioLeftColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;

  @media (width < $desktop) {
    width: 50%;
  }

  @media (width < $tablet) {
    width: 100%;
    margin-top: 50px;
  }
}

.audioLeftColumn span {
  width: auto;
  height: auto;
}

.audioRightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 500px;

  @media (width < $desktop) {
    width: 50%;
    padding: 0 0 0 30px;
  }

  @media (width < $tablet) {
    width: 100%;
    padding: 30px;
    max-width: none;
  }
}

.audioHeader {
  font-family: $PTSans-font;
  font-size: 26px;
  font-weight: 700;
  color: $vanilla-color;
  margin-bottom: 7px;
}

.audioSubheader {
  font-family: $PTSans-font;
  font-size: 12px;
  font-weight: 700;
  color: $vanilla-color;
  margin-bottom: 35px;
}

.audioDesc {
  font-family: $rosario-font;
  font-size: 18px;
  line-height: 29px;
  color: $vanilla-color;
}

// picture //

.pictureContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem;

  div {
    width: 75%;
    color: $latte-color;
  }

  @media screen and (width < $tablet) {
    padding: 1rem;

    div {
      width: 95;
      color: $latte-color;
    }
  }
}


// video //


.videoContainer {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem;

  @media screen and (width < $tablet) {
    margin: 0;
  }
}

.videoWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  margin-bottom: 1rem;
  padding-top: 56.25%;

  @media screen and (width < $tablet) {
    margin-bottom: 2rem;
  }
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.slideVideoText {
  max-width: 60%;
  width: 75%;
  color: $latte-color;

  @media screen and (width < $tablet) {
    width: 95%;
    margin: 0 auto;
  }

  @media screen and (width < $mobile) {
    max-width: unset;
  }
}



.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  z-index: 1;
  cursor: pointer;
}

.closeButton img {
  @media (width < $tablet) {
    width: 45px;
  }
}

.dot {
  background-color: $grayDot-color;
  width: 3px;
  height: 3px;
  border-radius: 3px;
  position: absolute;

  @media (width < $tablet) {
    display: none;
  }
}

.dot1 {
  top: 5px;
  left: 5px;
}

.dot2 {
  top: 5px;
  right: 5px;
}

.dot3 {
  bottom: 5px;
  left: 5px;
}

.dot4 {
  bottom: 5px;
  right: 5px;
}