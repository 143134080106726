@import "/src/styles/variables";

.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}

.slideWrapper {
  font-family: $PTSans-font;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4%;
  width: 100vw;

  @media screen and (width < $desktop) {
    height: 100%;
  }

  @media screen and (width < $tablet) {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
    margin-bottom: 0rem;
    width: 100%;
    gap: 30px;
  }
}

.slideImageBox {
  position: relative;
  min-width: 30%;
  aspect-ratio: 16/9;

  @media screen and (width < 1550px) {
    min-width: 450px;
    width: 40%;
    overflow: visible;
  }

  span {
    &:has(img):has(.imgMobile) {
      display: none !important;
    }
    &:has(img):has(.slideImage) {
      display: block !important;
    }
  }

  @media screen and (width < $tablet) {
    min-width: unset;
    height: unset;
    max-width: 100vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    aspect-ratio: unset;

    span {
      &:has(img):has(.imgMobile) {
        display: block !important;
      }
      &:has(img):has(.slideImage) {
        display: none !important;
      }
    }
  }
}

.slideImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: right;

  @media screen and (width < $desktop) {
    transform: translate(0, 0);
    object-position: center;
  }

  @media screen and (width < $tablet) {
    display: none;
    object-fit: contain;
    height: unset;
  }
}

.imgMobile {
  display: none;

  @media screen and (width < $tablet) {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
  }
}

.absoluteBanner {
  position: absolute;
  top: 105%;
  left: 60%;
  max-width: 500px;
  width: 100%;
  transform: translate(-50%, 0%);

  @media screen and (width < $tablet) {
    display: inline-block;
    transform: translate(0, 0);
    position: static;
    width: 95%;
    max-width: 95%;
    margin: 0 auto;
  }
}

.slideDataBox {
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 4%;

  @media screen and (width < 1550px) {
    flex-direction: column;
    justify-content: center;

    article {
      max-width: 80%;

      p {
        width: 100%;
        margin-top: 0.5rem;
      }
    }
  }

  @media screen and (width < $desktop) {
    flex-direction: column;
    width: 50%;
  }

  @media screen and (width < $tablet) {
    align-items: center;
    width: 100%;
    gap: 50px;

    article {
      padding: 0;
    }
  }
}

.slideChart {
  max-width: 480px;
  width: 100%;
  max-height: 500px;
  height: 60%;

  @media screen and (width < 1550px) {
    width: 90%;
    height: 250px;
    transform: translateX(-5%);
    align-self: center;

    image {
      height: 40px;
      transform: translate(5%, 15%);
    }
  }

  @media screen and (width < $tablet) {
    width: 100%;
    margin-bottom: 6rem;
    min-height: 350px;
    height: 400px;
    padding: 0;
    transform: translateX(-1rem);

    image {
      transform: translate(0%, 8%);
    }
  }
}

.slideChart h2 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-left: 2rem;
  margin-bottom: 2rem;

  @media screen and (width < 1550px) {
    margin-bottom: 1rem;
    font-size: 1rem;
    width: 120%;
  }

  @media screen and (width < $mobile) {
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0.06em;
    margin-left: 3rem;
    width: unset;
  }
}

.titleWrapper {
  @media screen and (width < $tablet) {
    justify-content: flex-start;
    margin-left: 0;
  }
}

.cardBoxAuthor {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;

  @media screen and (width < $tablet) {
    transform: translateY(0);
  }
}

.cardBoxAuthorBtn {
  background: no-repeat url("../../../public/assets/dotted-btn.svg") center/contain;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border: none;
  position: relative;
  z-index: 1;
}

.author {
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 2%;
  color: $light-color;
  font-weight: 700;
  text-transform: uppercase;
}

.sourceButton {
  font-family: $PTSans-font;
  color: $middlegray-color;
  background-color: unset;
  border: none;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: 0.07rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
  cursor: pointer;
  margin-left: 20px;
}

.sourceButton_icon {
  color: $vanilla-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  width: 20px;
  height: 20px;
  border: 1px solid $gray-border;
  border-radius: 50%;
}