@import "src/styles/variables.scss";

.overflow {
  background-color: #1f1e21;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  z-index: 12;
}

.container {
  background-color: $dark-blue;
  width: 100%;
  margin: 67px 183px;
  padding-top: 92px;
  position: relative;
  border: 1px solid #CFCFCF1A;
  box-shadow: -5px 0px 9px 0px #0000001A;
  border-radius: 2px;

  @media screen and (width < $desktop) {
    margin: 47px 103px;
  }

  @media screen and (width < $tablet) {
    margin: 15px 30px;
  }

  @media screen and (width < $mobile) {
    margin: 0;
    border: none;
  }
}

.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  background: transparent;
  border: none;
  z-index: 1;
  cursor: pointer;
}

.closeButton img {
  @media (width < $tablet) {
    width: 45px;
  }
}


.dot {
  background-color: $grayDot-color;
  width: 3px;
  height: 3px;
  border-radius: 3px;
  position: absolute;

  @media screen and (width < $tablet) {
    display: none;
  }
}

.dot1 {
  top: 5px;
  left: 5px;
}

.dot2 {
  top: 5px;
  right: 5px;
}

.dot3 {
  bottom: 5px;
  left: 5px;
}

.dot4 {
  bottom: 5px;
  right: 5px;
}