@import "/src/styles/variables";

.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}

.slideWrapper {
  font-family: $PTSans-font;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4%;
  width: 100vw;
  margin-left: 6rem;
  margin-right: 6rem;

  @media screen and (width < 1450px) {
    margin-right: 0;
  }

  @media screen and (width < $desktop) {
    height: 100%;
  }

  @media screen and (width < $tablet) {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-left: 0;
    margin-right: 0;
    height: 100%;
    margin-top: 5rem;
    margin-bottom: 0rem;
    width: 100%;
    gap: 70px;
  }
}

.slideDataBox {
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 4%;

  @media screen and (width < $tablet) {
    flex-direction: column;
    width: 100%;
    gap: 70px;
  }
}

.slideChart {
  width: 100%;
  max-width: 900px;
  max-height: 500px;
  height: 60%;

  @media screen and (width < 1450px) {
    max-height: none;
    width: 50%;
    min-height: 350px;
    padding: 0;
    transform: translateX(-1rem);
  }

  @media screen and (width < $mobile) {
    width: 85%;
  }
}

.slideChart h2 {
  font-size: 1.65rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.06em;
  margin-bottom: 2rem;
  text-transform: uppercase;
  margin-left: 2rem;
  margin-bottom: 2rem;

  @media screen and (width < $mobile) {
    font-size: 1.4rem;
    line-height: 2.2rem;
    letter-spacing: 0.06em;
    margin-left: 3rem;
  }
}

.slideChartWrapper {
  display: flex;
  align-items: center;
  height: 100%;

  @media screen and (width < $mobile) {
    flex-direction: column;
    height: auto;
  }
}

.chart1 {
  width: 80%;
  height: 100%;

  @media screen and (width < $mobile) {
    width: 100%;
    height: 590px;
  }
}

.chart2 {
  width: 20%;
  height: 100%;

  @media screen and (width < $mobile) {
    width: 100%;
    height: 290px;
  }
}

.titleWrapper {
  @media screen and (width < $tablet) {
    justify-content: flex-start;
    margin-left: 0;
  }
}