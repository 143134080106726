@import "/src/styles/variables";

.bannerBox {
  // background-color: #26282F61;
  background-color: #26282FB2;
  border: 1px solid #cfcfcf1a;
  box-shadow: 0px 4px 12px 0px #00000029;
  border-radius: 2px;
  padding: 1rem;
  position: relative;
  width: 100%;
}

.bannerBoxTransparent {
  background: transparent;
}

.bannerText {
  font-family: $PTSans-font;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.1rem;
  letter-spacing: 0.05em;
  text-align: center;
  text-transform: uppercase;
}

.dot {
  background-color: $grayDot-color;
  width: 2px;
  height: 2px;
  border-radius: 2px;
  position: absolute;
}

.dot1 {
  top: 3px;
  left: 3px;
}

.dot2 {
  top: 3px;
  right: 3px;
}

.dot3 {
  bottom: 3px;
  left: 3px;
}

.dot4 {
  bottom: 3px;
  right: 3px;
}