@import "src/styles/variables.scss";

.container {
  position: absolute;
  bottom: 1rem;
  right: 8rem;
  display: flex;
  align-items: center;
  gap: 35px;

  @media (max-width: $tablet) {
    position: static;
    flex-direction: column;
    align-items: flex-start;
    width: 90%;
    padding: 0 0 120px;
  }
}

.artifactsHeader {
  font-family: $PTSans-font;
  font-size: 0.9rem;
  font-weight: 700;
  color: $latte-color;
  text-transform: uppercase;

  @media (max-width: $mobile) {
    margin-top: 2rem;
    max-width: none;
    width: 100%;
  }
}

.item {
  position: relative;
  display: flex;
  align-items: center;
  height: 65px;
  cursor: pointer;

  @media (max-width: $tablet) {
    width: 100%;
  }
}

.itemDeco {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 0;
  border-color: transparent $khaki-color transparent transparent;
  transform: rotate(0deg);
  position: absolute;
  top: 0;
  right: 0;
}

.itemImageWrapper {
  background-color: $mediumblue-color;
  width: 75px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span:has(img) {
    display: flex !important;
    justify-content: center;
    align-items: center;
  }

  .audioIcon {
    margin: 0;
  }
}



.itemImageWrapper span {
  width: 70%;
  height: 100%;
  align-items: center;
}

.itemImageWrapper img {
  margin: -15px auto 0 auto;
}

.itemImageWrapper .icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.itemContent {
  background-color: $darkblue-color;
  max-width: 187px;
  min-width: 187px;
  height: 100%;
  padding: 7px 15px 15px 15px;

  @media (max-width: $tablet) {
    max-width: none;
    width: 100%;
  }
}

.itemType {
  font-family: $PTSans-font;
  font-size: 12px;
  font-weight: 700;
  color: $khaki-color;
  text-transform: uppercase;
  text-align: right;
  margin-bottom: 9px;
}

.itemName {
  font-family: $rosario-font;
  font-size: 13px;
  font-weight: 700;
  color: $vanilla-color;
  line-height: 16px;
}