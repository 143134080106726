@import "/src/styles/variables";

.bgImg {
  background: no-repeat url("../../../public/assets/content/reichBg.webp") center/cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}

.reichWrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 4%;

  @media screen and (width < $desktop) {
    justify-content: flex-start;
  }

  @media screen and (width < $tablet) {
    flex-wrap: wrap;
  }
}

.hansfrankBox {
  background: no-repeat url("../../../public/assets/content/hans_frank.webp");
  background-position: top right;
  background-size: cover;
  height: 100%;
  max-height: 1240px;
  width: 35%;
  margin-top: 150px;
  position: relative;

  @media screen and (width < 1800px) {
    width: 40%;
  }

  @media screen and (width < $desktop) {
    min-height: 60vh;
    max-height: 800px;
    margin-top: 0;
  }

  @media screen and (width < $tablet) {
    span:has(img) {
      width: 100%;
    }

    background: none;
    width: 100%;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 50px;
    gap: 8px;
  }
}


.hansfrankBoxInfo {
  position: absolute;
  bottom: 10%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 330px;
  width: 100%;

  @media screen and (width < $tablet) {
    transform: translate(0, 0);
    max-width: 95%;
    position: static;
    display: inline-block;
  }
}

.officersBox {
  display: flex;
  align-items: flex-start;
  column-gap: 1rem;

  @media screen and (width < $desktop) {
    justify-content: center;
  }

  @media screen and (width < $tablet) {
    flex-wrap: wrap;
    margin-bottom: 2rem;
    width: 100%;
  }
}

.slideImg {
  @media screen and (width > $tablet) {
    display: none;
  }

  display: inline-block;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
}


//PersonCard styles

.cardBox {
  font-family: $PTSans-font;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 456px;
  gap: 45px;
  min-width: 280px;

  @media screen and (width < $desktop) {
    max-width: 350px;
  }

  @media screen and (width < $mobile) {
    font-family: $PTSans-font;
    margin: 10px;
    display: flex;
    gap: 30px;
    min-width: 260px;
  }
}

.cardBoxImg {
  position: relative;
  width: fit-content;
  border-radius: 5px;
  outline: 1px solid $lightgray-color;
  border: 11px solid transparent;
}

.dot {
  width: 2px;
  height: 2px;
  border-radius: 4px;
  position: absolute;
  background-color: $grayDot-color;
}

.dot1 {
  top: -2%;
  left: -2%;
}

.dot2 {
  bottom: -2%;
  left: -2%;
}

.dot3 {
  top: -2%;
  right: -2%;
}

.dot4 {
  bottom: -2%;
  right: -2%;
}

.cardBoxImg img {
  border-radius: 5px;
}

.quoteSymbol {
  background: no-repeat url("../../../public/assets/quote-red.svg") center/contain;
  height: 30px;
}

.quote {
  font-size: 1.1rem;
  line-height: 1.9rem;
  font-weight: 700;
  letter-spacing: 0.02em;
  text-align: center;
  color: $vanilla-color;
  margin-top: 20px;
}

.quote span {
  width: auto;
}

.cardBoxAuthor {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cardBoxAuthorBtn {
  background: no-repeat url("../../../public/assets/dotted-btn.svg") center/contain;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border: none;
  position: relative;
  z-index: 1;
}

.author {
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 2%;
  color: $light-color;
  font-weight: 700;
  text-transform: uppercase;
}