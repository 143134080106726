@import "/src/styles/variables";

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 0 30px;
  
    @media screen and (width < $tablet) {
        align-items: flex-start;
    }
}
  
.header {
    font-family: $PTSans-font;
    font-size: 80px;
    font-weight: 700;
    color: $vanilla-color;
    margin-bottom: 100px;
    text-align: center;
    text-transform: uppercase;

    @media screen and (width < $tablet) {
        font-size: 45px;
        margin-bottom: 70px;
        text-align: center;
        width: 100%;
    }
}

.material_list {
    width: 100%;
    margin-left: 30px;
}

.material_link {
    font-family: $PTSans-font;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    margin-bottom: 20px;

    &:last-child {
        margin-bottom: 0;
    }

    a {
        color: $vanilla-color;
        &:visited {
            color: $accent-color;
        }
    }
}