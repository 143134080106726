@import "/src/styles/variables";

.container {
  padding: 40px 0;
  max-width: 870px;
  margin: 0 auto;
  padding: 0 30px;
}

.header {
  font-family: $PTSans-font;
  font-size: 80px;
  font-weight: 700;
  line-height: 75px;
  color: $vanilla-color;
  max-width: 870px;
  margin: 0 auto 100px auto;
  text-transform: uppercase;
  text-align: center;

  @media screen and (width < $tablet) {
    font-size: 45px;
    line-height: 45px;
    text-align: left;
    margin: 0 auto 70px auto;
  }
}

.video {
  max-width: 536px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 100px auto;

  @media screen and (width < $tablet) {
    margin: 0 -30px 70px -30px;
    max-width: none;
  }
}

.videoWrapper {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.content {
  margin: 0 auto 100px auto;
  max-width: 670px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (width < $tablet) {
    margin: 0 auto 70px auto;
  }
}

.category {
  font-family: $PTSans-font;
  font-size: 26px;
  font-weight: 700;
  color: $vanilla-color;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;

  @media screen and (width < $tablet) {
    font-size: 22px;
    line-height: 35px;
    text-align: left;
  }
}

.desc {
  font-family: $PTSans-font;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  color: $vanilla-color;
}

.partners {
  background-color: #fff;
  padding: 0 25px;
}

.top {
  display: flex;
  justify-content: center;
  background-color: #FBFBFB;
  padding: 35px 0;
  width: 100%;

  @media screen and (width < $tablet) {
    padding: 40px 0 12px 0;
  }
}

.topContent {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 970px;

  @media screen and (width < $tablet) {
    column-gap: 30px;
    justify-content: center;
  }

  @media screen and (width < $tablet) {
    column-gap: 0;
    justify-content: space-between;
  }
}

.topContent img:first-of-type {
  max-width: 150px;
}

.topContent img:last-of-type {
  max-width: 120px;
}

.topDesc {
  font-family: $PTSans-font;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: $dark-blue;
  max-width: 420px;
  position: relative;
  padding-left: 11px;

  @media screen and (width < $tablet) {
    max-width: none;
    order: -1;
    padding: 0 25px 30px 25px;
    margin-bottom: 30px;
  }
}

.topDesc::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 2px;
  height: 100%;
  background-color: $accent-color;

  @media screen and (width < $tablet) {
    width: 51px;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    bottom: 0;
  }
}

.partnersWrapper {
  max-width: 1000px;
  margin: 0 auto;
  padding: 30px 0;
}

.partnersDesc {
  font-family: $PTSans-font;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  color: $dark-blue;
  max-width: 807px;
  text-align: center;
  margin: 0 auto 80px auto;

  @media screen and (width < $tablet) {
    display: none;
  }
}

.main {
  display: flex;
  justify-content: center;
  column-gap: 100px;
  margin-bottom: 70px;

  @media screen and (width < $tablet) {
    flex-direction: column;
  }
}

.mainBlock {
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (width < $tablet) {
    margin-bottom: 38px;
  }
}

.mainBlock:first-of-type {
  @media screen and (width < $tablet) {
    order: 3;
  }
}

.mainBlock:nth-of-type(2) {
  @media screen and (width < $tablet) {
    order: 1;
  }
}

.mainBlock:last-of-type {
  @media screen and (width < $tablet) {
    order: 2;
  }
}

.mainBlock img {
  max-width: 120px;
}

.partnersCategory {
  font-family: $PTSans-font;
  font-size: 12px;
  font-weight: 700;
  color: $dark-blue;
  margin-bottom: 25px;
  text-align: center;
  text-transform: uppercase;
}

.partnersList {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 75px;
  row-gap: 42px;
  max-width: 1030px;
  margin: 0 auto;

  @media screen and (width < $tablet) {
    column-gap: 30px;
  }
}

.partnersListItem img {
  @media screen and (width < $tablet) {
    width: 90%;
  }
}

.partnersInfo {
  display: inline-block;
  max-width: 225px;
  margin-top: 1rem;
  text-align: center;
  font-family: $rosario-font;
  color: $dark-color;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5625rem;
}