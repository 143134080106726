@import "/src/styles/variables";

.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}

.slideWrapper {
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3%;
  margin-left: 10rem;
  margin-right: 6rem;

  @media screen and (width > 1700px) {
    gap: 6%;
  }

  @media screen and (width < $tablet) {
    margin-right: 0;
    flex-direction: column-reverse;
    justify-content: flex-start;
    margin-left: 0;
  }
}

.slideImageBox {
  position: relative;
  max-width: 50%;
  width: 40%;
  height: max-content;

  span {
    &:has(img):has(.slideImageMobile) {
      display: none !important;
    }
    &:has(img):has(.slideImage) {
      display: block !important;
    }
  }

  @media screen and (width < $desktop) {
    min-width: unset;
    width: 45%;
    overflow: visible;
  }

  @media screen and (width < $tablet) {
    min-width: unset;
    height: unset;
    max-width: 100vw;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      &:has(img):has(.slideImageMobile) {
        display: block !important;
      }
      &:has(img):has(.slideImage) {
        display: none !important;
      }
    }
  }
}

.slideImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: center;
  display: block;

  @media screen and (width < $tablet) {
    display: none;
  }
}

.slideImageMobile {
  display: none;

  @media screen and (width < $tablet) {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
  }
}

.absoluteBanner {
  position: absolute;
  bottom: -100px;
  left: 50%;
  max-width: 400px;
  width: 100%;
  transform: translate(-50%, 0%);

  @media screen and (width < $tablet) {
    display: inline-block;
    transform: translate(0, 0);
    position: static;
    width: 95%;
    max-width: 95%;
    margin: 0 auto;
  }
}

.rowRewerse {
  flex-direction: row-reverse;

  @media screen and (width < $tablet) {
    flex-direction: column;
  }
}

.video {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videoWrapper {
  margin: 100px 0 50px;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;

  @media screen and (width < $tablet) {
    margin: 0 0 50px;
  }
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.slideVideoText {
  max-width: 60%;

  @media screen and (width < $tablet) {
    width: 95%;
    margin: 0 auto;
  }

  @media screen and (width < $mobile) {
    max-width: unset;
  }
}

.slideImg {
  @media screen and (width < $tablet) {
    aspect-ratio: unset;
    width: unset;
    height: unset;
  }
}