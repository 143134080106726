@import "/src/styles/variables";

.slideArticle {
    max-width: 35%;

    @media screen and (width < 1350px) {
        max-width: 45%;
    }

    @media screen and (width < $tablet) {
        max-width: 100%;
        padding: 2rem;
    }
}
  
.slideArticle h2 {
    font-family: $PTSans-font;
    font-size: 1.625rem;
    font-weight: 700;
    line-height: 2.1875rem;
    letter-spacing: 0.0975rem;
    text-align: left;
    text-transform: uppercase;

    @media screen and (width < $tablet) {
        font-size: 1.3rem;
    }

    @media screen and (width > 1650px) {
        width: 90%;
    }
}
  
.slideArticle p {
    font-family: $rosario-font;
    font-size: 1.125rem;
    line-height: 1.8125rem;
    text-align: left;
    margin-top: 2rem;
    width: 90%;

    @media screen and (width < 1650px) {
        margin-top: 1rem;
        width: 95%;
    }

    @media screen and (width < 1350px) {
        margin-top: 1rem;
        width: 100%;
    }
}

.slideDesc {
    width: 80%;
    display: flex;
    justify-content: flex-end;

    .slideDescContent {
        font-family: $rosario-font;
        font-size: 0.875rem;
        font-style: italic;
        line-height: 1.25rem;
        width: 80%;
    }

    @media screen and (width < $tablet) {
        width: 100%;

        .slideDescContent {
            width: 70%;
        }
    }
}