@import "/src/styles/variables";


.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}
  
.slideWrapper {
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5%;
  margin-left: 6rem;
  @media screen and (width > 1700px) {
    gap: 10%;
  }

  @media screen and (width < $tablet) {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
  }
}
  
.rowRewerse {
  flex-direction: row-reverse;

  @media screen and (width < $tablet) {
    flex-direction: column;
  }
}

.slideFirstColumn {

  p {
    font-family: $PTSans-font;
    max-width: 480px;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.07em;
    text-align: left;
  }

  @media screen and (width < $tablet) {
    padding: 5rem 1.5rem;

    p {
        text-align: center;
    }
  }
}

.slideSecondColumn {
  max-width: 35%;
}

.slideSecondColumn h2 {
  font-family: $PTSans-font;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.06em;
  text-align: left;
}

.slideSecondColumn p {
  font-family: $rosario-font;
  font-size: 1.1rem;
  line-height: 1.9rem;
  text-align: left;
  margin-top: 2rem;
  width: 80%;

  @media screen and (width < $tablet) {
    max-width: 100%;
    padding: 1.5rem;
    h2 {
      font-size: 1.3rem;
    }
    p {
      width: 100%;
    }
  }
}

.whiteText {
  color: $vanilla-color;
}

.redText {
  color: $accent-color;
}