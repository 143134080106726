@import "/src/styles/variables";

.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}

.slideWrapper {
  height: 85%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4%;
  margin-left: 6rem;

  @media screen and (width > 1700px) {
    gap: 10%;
  }

  @media screen and (width < $tablet) {
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 0;
  }
}

.rowRewerse {
  flex-direction: row-reverse;

  @media screen and (width < $tablet) {
    flex-direction: column;
  }
}

.slideImgBox {
  max-width: 60%;
  min-width: 45%;
  width: 800px;
  max-height: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (width < $tablet) {
    max-width: unset;
    min-width: unset;
    max-height: unset;
    width: 100%;
    height: 100%;
    gap: 8px;
  }
}

.slideImg {
  width: 100%;
  aspect-ratio: 16/9;
  height: 100%;
  object-fit: contain;

  @media screen and (width > 1700px) {
    aspect-ratio: 4/3;
  }

  @media screen and (width < $tablet) {
    display: none;
  }
}

.slideImgText {
  max-width: 75%;
  margin-top: 2rem;

  @media screen and (width < $tablet) {
    width: 95%;
    margin: 0.5rem auto 0;
  }

  @media screen and (width < $mobile) {
    max-width: unset;
  }
}

.positionText {
  justify-content: flex-end;
}

.mobileImg {
  display: none;

  @media screen and (width < $tablet) {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
  }
}