@import "src/styles/variables.scss";

.legend {
  display: flex;
  flex-direction: column;
  padding: 12px 24px 5px 17px;
  border: 1px solid rgba(217, 217, 217, 0.1);
  background-color: $dark-color;
}

.legendTop {
  position: absolute;
  top: 22px;
  right: 17px;
}

.legendBottom {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 45px;

  @media (max-width: $mobile) {
    top: -90px;
    width: 200px;
    margin-top: 0;
  }
}

.legendWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.legendIcon {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 34px;
  height: 21px;

  @media (max-width: $mobile) {
    width: 28px;
    height: 17px;
  }
}

.legendTitle {
  font-family: $PTSans-font;
  font-size: 0.9rem;
  color: $vanilla-color;
  margin-left: 15px;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.7px;

  @media (max-width: $mobile) {
    font-size: 0.75rem;
  }
}

.legendSquare {
  width: 20px;
  height: 20px;
}