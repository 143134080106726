@import "/src/styles/variables";

@import "/src/styles/variables";

.modalBg {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(31, 30, 33, 0.75);
  backdrop-filter: blur(2px);

  @media (max-width: $mobile) {
    z-index: 12;
  }
}

.modalBorder {
  position: relative;
  max-width: 868px;
  width: 100%;
  height: max-content;
  padding: 11px;
  border: 1px solid #cfcfcf1a;
  border-radius: 2px;

  @media screen and (width < $tablet) {
    max-width: 100%;
    width: 100%;
    height: 100vh;
    height: 100dvh;
    padding: 0;
    border-radius: 0;
  }
}

.dot {
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: $grayDot-color;
  position: absolute;
  display: block;

  @media screen and (width < $tablet) {
    display: none;
  }
}

.dot1 {
  top: 3px;
  left: 3px;
}

.dot2 {
  top: 3px;
  right: 3px;
}

.dot3 {
  bottom: 3px;
  left: 3px;
}

.dot4 {
  bottom: 3px;
  right: 3px;
}

.modalWindow {
  position: relative;
  background-color: $dark-color;
  height: max-content;
  border-radius: 2px;
  overflow: hidden;
  display: flex;

  @media screen and (width < $tablet) {
    height: 100%;
    border-radius: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    overflow: auto;
  }
}

.modalTextBox {
  color: $vanilla-color;
  padding: 60px;

  @media screen and (width < $tablet) {
    max-width: 315px;
    padding: 30px;
  }
}

.header {
  font-family: $PTSans-font;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: 0.07rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
}

.infoIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.75rem;
    width: 20px;
    height: 20px;
    border: 1px solid $gray-border;
    border-radius: 50%;
}

.infoText {
  color: inherit;
  font-family: $rosario-font;
  text-decoration: none;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1.5625rem;
}

.infoLink {
    color: inherit;
    font-family: $rosario-font;
    text-decoration: none;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1.5625rem;
    word-wrap: break-word;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  width: 31px;
  height: 31px;
  border-radius: 50%;
  cursor: pointer;
  background: no-repeat url("../../../public/assets/closeBtn.svg") center/contain;

  &:focus {
    outline: 3px solid $dark-color;
  }
  &:focus-visible {
    outline: 2px solid $light-color;
  }

  @media (max-width: $tablet) {
    width: 45px;
    height: 45px;
  }
}

.modalImg {
  @media screen and (width < $tablet) {
    max-height: 60%;
    background-position: center;
    background-size: cover;
  }
}
