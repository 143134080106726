@import "src/styles/variables.scss";

.container {
  height: 100vh;
  width: 110px;
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  column-gap: 50px;
  z-index: 10;
  background: linear-gradient(90deg, #1D1E22 49.63%, rgba(29, 30, 34, 0) 94.77%);

  @media screen and (width < $tablet) {
    display: none;
  }
}

.pointList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 50px;
  height: 100%;
  position: relative;

  @media screen and (height < 800px) {
    height: 85%;
    row-gap: 30px;
  }

  @media screen and (width < $desktop) {
    row-gap: 25px;
  }
}

.pointList::after {
  content: "";
  width: 1px;
  height: 100%;
  background-color: rgba(207, 207, 207, 0.1);
  position: absolute;
  right: 9px;
}