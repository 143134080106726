@import "/src/styles/_variables.scss";

.navigation {
  position: fixed;
  z-index: 1;
  width: 100%;
  padding: 22px;
  font-family: $PTSans-font;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $mobile) {
    background-color: $dark-color;
    padding: 15px;
    height: 60px;
    top: 0;
  }
}

.navigationFirstBox {
  display: flex;
  align-items: center;
  gap: 40px;
}

.burgerBtn {
  position: relative;
  height: 23px;
  width: 26px;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  border: none;

  &::after,
  &::before {
    content: "";
    position: absolute;
    background-color: $accent-color;
    width: 100%;
    height: 3px;
    border-radius: 1px;
    transition: all 250ms;
  }

  &::after {
    top: 0;
    left: 0;
  }

  &::before {
    bottom: 0;
    left: 0;
  }
}

.burgerBtnClose {
  position: absolute;
  top: 22px;
  left: 22px;
}

.btnBar {
  display: inline-block;
  background-color: $accent-color;
  width: 100%;
  height: 3px;
  border-radius: 1px;
  transition: all 250ms;
}

.burgerBtnActive {
  &::before {
    top: 50%;
    transform: rotate(-45deg);
  }

  &::after {
    top: 50%;
    transform: rotate(45deg);
  }
}

.burgerBtnActive .btnBar {
  opacity: 0;
}

.navigationData {
  color: $vanilla-color;
  text-transform: uppercase;
  font-size: 1rem;
  line-height: 1.1rem;
  letter-spacing: 0.06em;
  font-weight: 900;
}

.chapter {
  text-transform: uppercase;
  letter-spacing: 0.06em;
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 0.9rem;
  color: $accent-color;
}

.helpfullLinks {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 33px;
  fill: currentColor;
  color: $vanilla-color;
  border-left: 1px solid $gray-border;
  padding: 0 83px;
  height: 50%;

  button {
    font-family: $PTSans-font;
  }

  img {
    filter: brightness(0) invert(1);
    height: 13px;
    width: 22px;
  }

  @media screen and (width < $tablet) {
    padding: 0 17px;
    gap: 17px;
    margin-bottom: 70px;
  }
}

.helpfullLinks span {
  width: auto;
  height: auto;
}

.link {
  font-weight: 600;
  font-size: 32px;
  color: $vanilla-color;
  text-decoration: none;
  text-transform: uppercase;
  background-color: transparent;
  border: none;
  cursor: pointer;
  white-space: nowrap;

  &:hover,
  &:focus,
  &:target {
    color: $accent-color;
  }

  &:is(a) {
    display: inline-block;
    padding: 1px 6px;
  }

  @media screen and (width < $tablet) {
    font-size: 15px;
  }
}

.burgerMenu {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  padding: 22px;
  background-color: #1d1e22;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (width < $tablet) {
    padding: 0;
    flex-direction: column;
    align-items: flex-start;
  }
}

.burgerMenuContainer {
  max-height: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100vw;

  @media screen and (width < $tablet) {
    max-height: 75%;
  }
}

.burgerMenuListWrapper {
  display: flex;
  max-height: 80%;
  height: 100%;
  width: 100%;

  @media screen and (width < $tablet) {
    max-height: 85%;
    margin-top: 70px;
  }
}

.burgerMenuList {
  padding-left: 0;
  margin-left: -2rem;
  height: 100%;
  width: calc(100% - 4rem);
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  column-gap: 4rem;

  @media screen and (width < $tablet) {
    flex-wrap: nowrap;
    width: 100vw;
    height: 85%;
    max-height: 80dvh;
    margin-left: 0;
    margin-bottom: 80px;
  }
}