@import "/src/styles/variables";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 30px;

  @media screen and (width < $tablet) {
    align-items: flex-start;
  }
}

.header {
  font-family: $PTSans-font;
  font-size: 80px;
  font-weight: 700;
  color: $vanilla-color;
  margin-bottom: 100px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (width < $tablet) {
    font-size: 45px;
    margin-bottom: 70px;
    text-align: center;
    width: 100%;
  }
}

.role {
  font-family: $PTSans-font;
  font-size: 26px;
  font-weight: 700;
  line-height: 35px;
  color: $vanilla-color;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  max-width: 935px;

  @media screen and (width < $tablet) {
    font-size: 22px;
    text-align: left;
  }
}

.author {
  font-family: $PTSans-font;
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
  color: $vanilla-color;
  text-align: center;
  margin-bottom: 100px;
  max-width: 696px;

  @media screen and (width < $tablet) {
    font-size: 18px;
    text-align: left;
    margin-bottom: 70px;
  }
}