@import "/src/styles/variables";

.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}

.slideWrapper {
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4%;
  margin-left: 10rem;

  @media screen and (width > 1700px) {
    gap: 6%;
  }

  @media screen and (width < $tablet) {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
  }
}

.rowRewerse {
  flex-direction: row-reverse;

  @media screen and (width < $tablet) {
    flex-direction: column;
  }
}

.slideImgBox {
  max-width: 60%;
  width: 800px;
  max-height: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  span {
    &:has(img):has(.slideImageMobile) {
      display: none !important;
    }
    &:has(img):has(.slideImg) {
      display: block !important;
    }
  }

  @media screen and (width < $tablet) {
    max-width: unset;
    width: 100%;
    height: 100%;
    gap: 8px;

    span {
      &:has(img):has(.slideImageMobile) {
        display: block !important;
      }
      &:has(img):has(.slideImg) {
        display: none !important;
      }
    }
  }
}

.slideImg {
  width: 100%;
  aspect-ratio: 16/9;
  object-fit: contain;

  @media screen and (width > 1700px) {
    aspect-ratio: unset;
    width: unset;
  }

  @media screen and (width < $tablet) {
    display: none;
  }
}

.slideImageMobile {
  display: none;

  @media screen and (width < $tablet) {
    display: inline-block;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
}

.slideImgText {
  max-width: 75%;
  margin-top: 1rem;

  @media screen and (width < $tablet) {
    width: 95%;
    margin: 0.5rem auto 0;
  }

  @media screen and (width < $mobile) {
    max-width: unset;
  }
}

.quote {
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (width < 1350px) {
    max-width: 45%;
  }

  @media screen and (width < $tablet) {
    max-width: none;
    margin-bottom: 50px;
  }
}

.quote span {
  width: auto;
}

.quoteText {
  font-family: $PTSans-font;
  font-size: 20px;
  font-weight: 700;
  color: $vanilla-color;
  line-height: 27px;
  letter-spacing: 7%;
  text-align: center;
  margin: 20px 0;
}

.quoteAuthor {
  font-family: $PTSans-font;
  font-size: 14px;
  font-weight: 700;
  color: $vanilla-color;
  line-height: 20px;
  letter-spacing: 5%;
  text-align: center;
  margin-bottom: 5px;
}

.quoteDesc {
  font-family: $PTSans-font;
  font-size: 14px;
  font-weight: 400;
  color: $vanilla-color;
  line-height: 22px;
  letter-spacing: 5%;
  text-align: center;
  font-style: italic;
}