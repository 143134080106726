@import 'src/styles/_variables.scss';

.chapter {
  text-decoration: none;
  text-transform: uppercase;
  padding: 25px 0 25px 150px;
  border-bottom: 1px solid $gray-border;
  width: 100%;
  min-width: 500px;
  max-width: 45%;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-family: $PTSans-font;

  &:hover,
  &:focus,
  &:target {
    color: $accent-color;
  }

  @media screen and (width < $tablet) {
    margin-bottom: 0;
    max-width: unset;
    min-width: unset;
    padding: 23px 20px;
  }
}

.chapter:last-of-type {
  border-bottom: 1px solid transparent;
}

.chapter:hover {
  background-color: $darkblue-color;
}

.chapterActive {
  background-color: $accent-color;
}

.chapterNumber {
  font-family: $PTSans-font;
  color: $vanilla-color;
  display: inline-block;
  min-width: 40px;
  text-align: right;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
  letter-spacing: 0.12rem;

  @media screen and (width < $tablet) {
    margin-left: 10px;
    font-size: 1.5rem;
    line-height: 2rem;
    min-width: 30px;
  }
}

.chapterName {
  font-family: $PTSans-font;
  color: $vanilla-color;
  margin-left: 64px;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.075rem;

  @media screen and (width < $tablet) {
    margin-left: 32px;
    font-size: 0.95rem;
    line-height: 1.25rem;
  }
}