@import "/src/styles/variables";

.mBtnBox {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  outline: none;
  border: none;
  display: none;
  font-family: $PTSans-font;
  color: $vanilla-color;
  z-index: 3;

  @for $i from 1 through 7 {
    &[datatype = chapter#{$i}] {
      background-image: url("../../../../public/assets/mobile_btn_#{$i}.webp");
    }
  }
}

.mBtnPin {
  width: 57px;
  height: 57px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  letter-spacing: 0.07em;
  border: 1px solid #cfcfcf1a;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background: linear-gradient(0deg,
      rgba(207, 207, 207, 0.1),
      rgba(207, 207, 207, 0.1)),
    linear-gradient(0deg, #2a2c32, #2a2c32);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mBtn {
  font-weight: 700;
  cursor: pointer;
  width: 100%;
  position: relative;
  padding: 35px 10px;
  margin: 0;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 1.375rem;
  font-weight: 700;
  line-height: 2.1875rem;
  letter-spacing: 0.0825rem;
  text-transform: uppercase;
  color: inherit;

  p {
    margin: 0;

    &:first-child {
      font-size: 0.75rem;
      line-height: 1.125rem;
      letter-spacing: 0.0375rem;
    }
  }
}

@media screen and (width < $tablet) {
  .mBtnBox {
    display: block;
    margin-top: 100px;
  }
}