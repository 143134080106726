@import url("https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rosario:wght@400;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-size: 16px;
}
@media screen and (min-width: 990px) {
  html {
    font-size: calc(10px + 6 * ((100vw - 990px) / 610));
  }
}
@media screen and (min-width: 1600px) {
  html {
    font-size: 16px;
  }
}

body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  overflow: hidden;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.container {
  height: 100vh;
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
}

.ps__thumb-y {
  background-color: #FF4337 !important;
}

.swiper {
  padding-bottom: 200px !important;
  margin-bottom: -200px;
}

.swiper-wrapper {
  align-items: center;
}

.swiper-slide {
  opacity: 0.05;
}

.swiper-slide .slideTitle {
  display: none;
}

.swiper-slide img {
  max-width: 233px;
}

.swiper-slide-active {
  opacity: 1;
}

.swiper-slide-active img {
  max-width: 360px;
  opacity: 1;
}

.zoom {
  visibility: hidden;
  background: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: 250ms;

}
.swiper-slide-active:hover .zoom {
  visibility: visible;
}

@media screen and (width < 992px) {
  .swiper-slide-active .zoom {
    visibility: visible !important;
  }
}

.swiper-slide-active .slideTitle {
  min-width: 451px;
  display: block;
  color: #fcf3e7;
  position: absolute;
  bottom: -120px;
}

@media screen and (width < 1400px) {
  .swiper-slide-active .slideTitle {
    bottom: -90px;
  }
}

@media screen and (width < 992px) {
  .swiper-slide-active .slideTitle {
    bottom: 20px;
    margin: 0 auto;
  }
  .swiper {
    padding-bottom: 0 !important;
    margin-bottom: 0;
  }
}

@media screen and (width < 768px) {
  .swiper-slide-active .slideTitle {
    min-width: 80%;
  }
}

.fp-watermark {
  display: none;
}

.section {
  align-items: center;
}

@media screen and (width < 768px) {
  .swiper-slide img {
    display: none;
  }

  .swiper-slide-active img {
    max-width: 233px;
    display: block;
  }
}

@media screen and (width < 992px) {
  .container {
    height: unset;
    overflow: auto;
    padding: 0;
  }

  .section {
    padding-top: 60px;
  }
}

@media screen and (width < 1281px) {
  .container {
  }
}



@media screen and (height < 850px) {
  .swiper {
    margin-bottom: 0;
  }
}

img {
  max-width: 100%;
}

.lazy-load-image-background {
  display: inline-flex;
  width: 100%;
  height: 100%;
}

@media screen and (width < 992px) {
  .lazy-load-image-background {
    width: auto;
    height: auto;
  }
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #ff4337;
  border-radius: 10px;
}