@import "/src/styles/variables";

.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}

.slideWrapper {
  font-family: $PTSans-font;
  height: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4%;
  width: 100vw;
  margin: 0 2rem 0 8rem;

  @media screen and (width > 1920px) {
    gap: 6%;
  }

  @media screen and (width < $desktop) {
    height: 100%;
  }

  @media screen and (width < $tablet) {
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin: 0;
    height: 100%;
    width: 100%;
    gap: 30px;
  }
}

.slideLeftColumn {
    position: relative;
    width: 100%;
    max-width: 500px;

    article {
        max-width: unset;

        p {
            width: 100%;
        }
    }

    @media screen and (width < 1550px) {
        // min-width: 450px;
        // width: 40%;
        // overflow: visible;
    }

    @media screen and (width < $tablet) {
        // min-width: unset;
        // height: unset;
        max-width: 100vw;
        // width: 100%;
    }
}

.textBox {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    span:has(img) {
        width: max-content;
    }

    @media screen and (width < $tablet) {
        padding: 0 2rem;
    }
}

.textBox_main {
    margin: 20px 0 45px;
    text-align: center;
    max-width: 90%;
    font-family: $PTSans-font;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.6875rem;
    letter-spacing: 0.0875rem;

    @media screen and (width < $tablet) {
        max-width: 100%;
        font-size: 1.1rem;
        line-height: 1.2875rem;
    }
}

.textBox_desc {
    text-align: center;  
    max-width: 75%;
    font-family: $PTSans-font;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.25rem;
    letter-spacing: 0.04375rem;
    text-transform: uppercase;

    @media screen and (width < $tablet) {
        font-size: 0.8rem;
    }
}

.slideDataBox {
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8%;

  @media screen and (width > 1920px) {
    width: 60%;
    gap: 16%;
  }

  @media screen and (width < 1550px) {
    flex-direction: column;
    justify-content: center;
    gap: 4%;
  }

  @media screen and (height < 850px) {
    align-self: flex-start;
  }

  @media screen and (width < $desktop) {
    flex-direction: column;
    width: 50%;
  }

  @media screen and (width < $tablet) {
    align-items: center;
    width: 100%;
    gap: 50px;
    padding: 0 1rem;
  }
}

.slideDataBox_infoContent {
    max-width: 500px;
    width: 100%;

    @media screen and (width < $tablet) {
        padding: 0 2rem;
    }
}

.slideDataBox_infoContent_txt {
    font-family: $rosario-font;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.8125rem;
}

.slideChart {
  max-width: 500px;
  width: 100%;
  max-height: 400px;
  height: 60%;
  margin-bottom: 5rem;

  @media screen and (height < 900px) {
    margin-bottom: 0;
  }

  @media screen and (width < 1550px) {
    width: 90%;
    height: 250px;
    transform: translateX(-5%);
    align-self: center;

    image {
      height: 40px;
      transform: translate(5%, 15%);
    }
  }

  @media screen and (width < $tablet) {
    width: 100%;
    margin-bottom: 6rem;
    min-height: 350px;
    height: 400px;
    padding: 0;
    transform: translateX(-1rem);

    image {
      transform: translate(0%, 8%);
    }
  }
}

.slideChart h2 {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 2rem;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-left: 2rem;
  margin-bottom: 2rem;

  @media screen and (width < 1550px) {
    margin-bottom: 1rem;
    font-size: 1rem;
    width: 120%;
  }

  @media screen and (width < $mobile) {
    font-size: 1rem;
    line-height: 1.2rem;
    letter-spacing: 0.06em;
    margin-left: 3rem;
    width: unset;
  }
}

.titleWrapper {
  @media screen and (width < $tablet) {
    justify-content: flex-start;
    margin-left: 0;
  }
}

.cardBoxPicture {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;

  @media screen and (width < $tablet) {
  }
}

.cardBoxPictureBtn {
  background: no-repeat url("../../../public/assets/dotted-btn.svg") center/contain;
  height: 40px;
  width: 40px;
  cursor: pointer;
  border: none;
  position: relative;
  z-index: 1;
}

.author {
  font-size: 1rem;
  line-height: 1.4rem;
  letter-spacing: 2%;
  color: $light-color;
  font-weight: 700;
  text-transform: uppercase;
}

.sourceButton {
  font-family: $PTSans-font;
  color: $middlegray-color;
  background-color: unset;
  border: none;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.375rem;
  letter-spacing: 0.07rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
  cursor: pointer;
  margin-left: 20px;
}

.sourceButton_icon {
  color: $vanilla-color;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.75rem;
  width: 20px;
  height: 20px;
  border: 1px solid $gray-border;
  border-radius: 50%;
}