@import "/src/styles/variables";

.bgImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media screen and (width < $tablet) {
    background-image: none !important;
    background: $dark-color;
  }
}

.slideWrapper {
  height: 85%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4%;
  margin-left: 7rem;

  @media screen and (width > 1700px) {
    gap: 10%;
  }

  @media screen and (width < $tablet) {
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 0;
  }
}

.rowRewerse {
  flex-direction: row-reverse;

  @media screen and (width < $tablet) {
    flex-direction: column;
  }
}

.video {
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videoWrapper {
  margin-bottom: 50px;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
}

.videoPlayer {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.slideVideoText {
  max-width: 60%;

  @media screen and (width < $tablet) {
    width: 95%;
    margin: 0 auto;
  }

  @media screen and (width < $mobile) {
    max-width: unset;
  }
}

.slideImg {
  @media screen and (width < $tablet) {
    aspect-ratio: unset;
    width: unset;
    height: unset;
  }
}